@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

/* Fuente regular */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/Nunito-Regular.ttf');
}

/* Fuente negritas */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/Nunito-Medium.ttf');
  font-weight: 600;
}

/* Fuente negritas */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/Nunito-Bold.ttf');
  font-weight: bold;
}

/* Fuente cursiva */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/Nunito-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

/* Fuente negrita cursiva */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/Nunito-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Fuente light */
@font-face {
  font-family: Avenir;
  src: url('./Fonts/Nunito-Light.ttf');
  font-weight: 100;
}

/* Nunito fonts, index */
@font-face {
  font-family: "Nunito bold";
  src: url('./Fonts/Nunito-Bold.ttf');
}

@font-face {
  font-family: "Nunito bolder";
  src: url('./Fonts/Nunito-ExtraBold.ttf');
}

@font-face {
  font-family: "Nunito semibold";
  src: url('./Fonts/Nunito-SemiBold.ttf');
}

body, html {
  font-family: Avenir, sans-serif;
  padding: 0px;
  margin: 0px;
  width: 100%;
  overflow-x: hidden;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

a[target] {
  color: blue;
  font-weight: bold;
}

.input-pattern-validator:invalid {
  border-color: red !important;
}

.offers-item:hover .offers-tooltip {
  background-color: #6F8197 !important;
}

.expert-card-button:hover .icon-phone {
  stroke: white;
}

.expert-card-button .icon-video {
  fill: #14336C;
}

.expert-card-button:hover .icon-video.available {
  fill: white;
  stroke: white;
}

/* custom checkbox */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #14336C !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: 1px solid #CD4236;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/** nunito fonts */
.nunito-font-bold {
  font-family: "Nunito bold", sans-serif;
}

.nunito-font-semibold {
  font-family: "Nunito semibold", sans-serif;
}

.nunito-font-bolder {
  font-family: "Nunito bolder", sans-serif;
}

.navitem:hover.hover-semibold {
  font-weight: 600;
  margin-bottom: -7px !important;
}

.navitem:hover.with-menu.hover-semibold {
  font-weight: 600;
  margin-bottom: 3px !important;
}

/* blick effect */
.agenda.blink {
  animation: blinker 2s ease-in-out infinite;
}

@keyframes blinker {
  0%, 100% {
    background-color: #F2CCC9;
  }
  50% {
    background-color: #D2544A;
    color: white;
  }
}