@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap);
/* purgecss start ignore */

.opition-carousel-item {
  min-width: 78%;
}

@media (min-width: 640px) {
  .opition-carousel-item {
    min-width: 75%;
  }
}

@media (min-width: 768px) {
  .opition-carousel-item {
    min-width: 65%;
  }
}

@media (min-width: 1280px) {
  .opition-carousel-item {
    min-width: 75%;
  }
}

.home-service-item{
  transition: box-shadow linear 0.2s;
}

.home-service-item:hover {
  background: #FFFFFF;
  box-shadow: 5px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

/* purgecss end ignore */
/*
purgecss start ignore*/

.zodiacIcon:hover svg path{
  stroke: white;
}

.zodiac-element, .base-zodiac {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  height: 170px;
  transition: margin-left 200ms linear;
  overflow: hidden;
}

.zodiac-element:first-of-type {
  margin-left: -500%;
}

.zodiac-element:first-of-type.margin-3 {
  margin-left: -600%;
}

.zodiac-element:first-of-type.margin-4 {
  margin-left: -700%;
}

.zodiac-element:first-of-type.margin-5 {
  margin-left: -800%;
}

.zodiac-element:first-of-type.margin-6 {
  margin-left: -900%;
}

.zodiac-element:first-of-type.margin-7 {
  margin-left: -1000%;
}

.zodiac-element:first-of-type.margin-8 {
  margin-left: -1100%;
}

.zodiac-element:first-of-type.margin-9 {
  margin-left: -1200%;
}

.zodiac-element:first-of-type.margin-10 {
  margin-left: -1300%;
}

.zodiac-element:first-of-type.margin-11 {
  margin-left: -1400%;
}

.zodiac-element:first-of-type.margin-12 {
  margin-left: -1500%;
}

.zodiac-element:first-of-type.margin-13 {
  margin-left: -1600%;
}

.zodiac-element:first-of-type.margin-14 {
  margin-left: -1700%;
}

.zodiac-element:first-of-type.margin-15 {
  margin-left: -1800%;
}

/*
.zodiac-element.animating {
    min-width: 0px;
    width: 0px !important;
    overflow: hidden;
}*/

@media (min-width: 640px) {
  .zodiac-element {
    width: 33%;
    min-width: 33%;
  }

  .zodiac-element:first-of-type {
    margin-left: -133.3%;
  }

  .zodiac-element:first-of-type.margin-3 {
    margin-left: -166.7%;
  }

  .zodiac-element:first-of-type.margin-4 {
    margin-left: -200%;
  }

  .zodiac-element:first-of-type.margin-5 {
    margin-left: -233.3%;
  }

  .zodiac-element:first-of-type.margin-6 {
    margin-left: -266.7%;
  }

  .zodiac-element:first-of-type.margin-7 {
    margin-left: -300%;
  }

  .zodiac-element:first-of-type.margin-8 {
    margin-left: -333.3%;
  }

  .zodiac-element:first-of-type.margin-9 {
    margin-left: -366.7%;
  }

  .zodiac-element:first-of-type.margin-10 {
    margin-left: -400%;
  }

  .zodiac-element:first-of-type.margin-10 {
    margin-left: -433.3%;
  }

  .zodiac-element:first-of-type.margin-11 {
    margin-left: -466.7%;
  }

  .zodiac-element:first-of-type.margin-12 {
    margin-left: -500%;
  }

  .zodiac-element:first-of-type.margin-13 {
    margin-left: -533.3%;
  }

  .zodiac-element:first-of-type.margin-14 {
    margin-left: -566.7%;
  }

  .zodiac-element:first-of-type.margin-15 {
    margin-left: -600%;
  }
}

@media (min-width: 768px) {
  .zodiac-element {
    width: 20%;
    min-width: 20%;
  }

  .zodiac-element:first-of-type {
    margin-left: -60%;
  }

  .zodiac-element:first-of-type.margin-3 {
    margin-left: -80%;
  }

  .zodiac-element:first-of-type.margin-4 {
    margin-left: -100%;
  }

  .zodiac-element:first-of-type.margin-5 {
    margin-left: -120%;
  }

  .zodiac-element:first-of-type.margin-6 {
    margin-left: -140%;
  }

  .zodiac-element:first-of-type.margin-7 {
    margin-left: -160%;
  }

  .zodiac-element:first-of-type.margin-8 {
    margin-left: -180%;
  }

  .zodiac-element:first-of-type.margin-9 {
    margin-left: -200%;
  }

  .zodiac-element:first-of-type.margin-10 {
    margin-left: -220%;
  }

  .zodiac-element:first-of-type.margin-11 {
    margin-left: -240%;
  }

  .zodiac-element:first-of-type.margin-12 {
    margin-left: -260%;
  }

  .zodiac-element:first-of-type.margin-13 {
    margin-left: -280%;
  }

  .zodiac-element:first-of-type.margin-14 {
    margin-left: -300%;
  }

  .zodiac-element:first-of-type.margin-15 {
    margin-left: -320%;
  }
}

@media (min-width: 1280px) {
  .zodiac-element {
    width: 14.3%;
    min-width: 14.3%;
  }

  .zodiac-element:first-of-type {
    margin-left: -28.5%;
  }

  .zodiac-element:first-of-type.margin-3 {
    margin-left: -42.8%;
  }

  .zodiac-element:first-of-type.margin-4 {
    margin-left: -57.14%;
  }

  .zodiac-element:first-of-type.margin-5 {
    margin-left: -71.43%;
  }

  .zodiac-element:first-of-type.margin-6 {
    margin-left: -85.7%;
  }

  .zodiac-element:first-of-type.margin-7 {
    margin-left: -100%;
  }

  .zodiac-element:first-of-type.margin-8 {
    margin-left: -114%;
  }

  .zodiac-element:first-of-type.margin-9 {
    margin-left: -128.5%;
  }

  .zodiac-element:first-of-type.margin-10 {
    margin-left: -157.14%;
  }

  .zodiac-element:first-of-type.margin-11 {
    margin-left: -171.43%;
  }

  .zodiac-element:first-of-type.margin-12 {
    margin-left: -185.7%;
  }

  .zodiac-element:first-of-type.margin-13 {
    margin-left: -200%;
  }

  .zodiac-element:first-of-type.margin-14 {
    margin-left: -214%;
  }

  .zodiac-element:first-of-type.margin-15 {
    margin-left: -228.5%;
  }
}

/*
purgecss end ignore*/
/* purgecss start ignore */

.style-container ul {
  list-style: disc outside none;
  list-style: initial;
  padding-left: 1.5em;
}

.style-container h1 {
  margin-bottom: 1em;
}

/* purgecss end ignore */
/* purgecss start ignore */

.service-element {
  width: 100%;
  min-width: 100%;
  transition: margin-left 200ms linear;
}

.service-element:first-of-type.index-2 {
  margin-left: -200%;
} .service-element:first-of-type.index-3 {
  margin-left: -300%;
} .service-element:first-of-type.index-4 {
  margin-left: -400%;
} .service-element:first-of-type.index-5 {
  margin-left: -500%;
} .service-element:first-of-type.index-6 {
  margin-left: -600%;
} .service-element:first-of-type.index-7 {
  margin-left: -700%;
} .service-element:first-of-type.index-8 {
  margin-left: -800%;
} .service-element:first-of-type.index-9 {
  margin-left: -900%;
} .service-element:first-of-type.index-10 {
  margin-left: -100%;
} .service-element:first-of-type.index-11 {
  margin-left: -1200%;
} .service-element:first-of-type.index-12 {
  margin-left: -1300%;
}

@media (min-width: 640px) {
  .service-element {
    width: 33%;
    min-width: 33%;
  }

  .service-element:first-of-type.index-2 {
    margin-left: -33.3%;
  } .service-element:first-of-type.index-3 {
    margin-left: -66.67%;
  } .service-element:first-of-type.index-4 {
    margin-left: -100%;
  } .service-element:first-of-type.index-5 {
    margin-left: -133.3%;
  } .service-element:first-of-type.index-6 {
    margin-left: -166.67%;
  } .service-element:first-of-type.index-7 {
    margin-left: -200%;
  } .service-element:first-of-type.index-8 {
    margin-left: -233.3%;
  } .service-element:first-of-type.index-9 {
    margin-left: -266.67%;
  } .service-element:first-of-type.index-10 {
    margin-left: -300%;
  } .service-element:first-of-type.index-11 {
    margin-left: -333.3%;
  } .service-element:first-of-type.index-12 {
    margin-left: -367.67%;
  }
}

@media (min-width: 1280px) {
  .service-element {
    width: 20%;
    min-width: 20%;
  }

  .service-element:first-of-type.index-2 {
    margin-left: -0%;
  } .service-element:first-of-type.index-3 {
    margin-left: -20%;
  } .service-element:first-of-type.index-4 {
    margin-left: -40%;
  } .service-element:first-of-type.index-5 {
    margin-left: -60%;
  } .service-element:first-of-type.index-6 {
    margin-left: -80%;
  } .service-element:first-of-type.index-7 {
    margin-left: -100%;
  } .service-element:first-of-type.index-8 {
    margin-left: -120%;
  } .service-element:first-of-type.index-9 {
    margin-left: -140%;
  } .service-element:first-of-type.index-10 {
    margin-left: -160%;
  } .service-element:first-of-type.index-11 {
    margin-left: -180%;
  } .service-element:first-of-type.index-12 {
    margin-left: -200%;
  }
}

/* purgecss end ignore */
/* purgecss start ignore */

/* Fuente regular */

@font-face {
  font-family: Avenir;

  src: url(/static/media/Nunito-Regular.bb0b7104.ttf);
}

/* Fuente negritas */

@font-face {
  font-family: Avenir;

  src: url(/static/media/Nunito-Medium.27fc7902.ttf);

  font-weight: 600;
}

/* Fuente negritas */

@font-face {
  font-family: Avenir;

  src: url(/static/media/Nunito-Bold.90e51595.ttf);

  font-weight: bold;
}

/* Fuente cursiva */

@font-face {
  font-family: Avenir;

  src: url(/static/media/Nunito-Italic.f9e1db7c.ttf);

  font-weight: normal;

  font-style: italic;
}

/* Fuente negrita cursiva */

@font-face {
  font-family: Avenir;

  src: url(/static/media/Nunito-BoldItalic.4fd1245b.ttf);

  font-weight: bold;

  font-style: italic;
}

/* Fuente light */

@font-face {
  font-family: Avenir;

  src: url(/static/media/Nunito-Light.a6b4915b.ttf);

  font-weight: 100;
}

/* Nunito fonts, index */

@font-face {
  font-family: "Nunito bold";

  src: url(/static/media/Nunito-Bold.90e51595.ttf);
}

@font-face {
  font-family: "Nunito bolder";

  src: url(/static/media/Nunito-ExtraBold.42043534.ttf);
}

@font-face {
  font-family: "Nunito semibold";

  src: url(/static/media/Nunito-SemiBold.73450f76.ttf);
}

body, html {
  font-family: Avenir, sans-serif;
  padding: 0px;
  margin: 0px;
  width: 100%;
  overflow-x: hidden;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

a[target] {
  color: blue;
  font-weight: bold;
}

.input-pattern-validator:invalid {
  border-color: red !important;
}

.offers-item:hover .offers-tooltip {
  background-color: #6F8197 !important;
}

.expert-card-button:hover .icon-phone {
  stroke: white;
}

.expert-card-button .icon-video {
  fill: #14336C;
}

.expert-card-button:hover .icon-video.available {
  fill: white;
  stroke: white;
}

/* custom checkbox */

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #14336C !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: 1px solid #CD4236;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/** nunito fonts */

.nunito-font-bold {
  font-family: "Nunito bold", sans-serif;
}

.nunito-font-semibold {
  font-family: "Nunito semibold", sans-serif;
}

.nunito-font-bolder {
  font-family: "Nunito bolder", sans-serif;
}

.navitem:hover.hover-semibold {
  font-weight: 600;
  margin-bottom: -7px !important;
}

.navitem:hover.with-menu.hover-semibold {
  font-weight: 600;
  margin-bottom: 3px !important;
}

/* blick effect */

.agenda.blink {
  -webkit-animation: blinker 2s ease-in-out infinite;
  animation: blinker 2s ease-in-out infinite;
}

@-webkit-keyframes blinker {
  0%, 100% {
    background-color: #F2CCC9;
  }

  50% {
    background-color: #D2544A;
    color: white;
  }
}

@keyframes blinker {
  0%, 100% {
    background-color: #F2CCC9;
  }

  50% {
    background-color: #D2544A;
    color: white;
  }
}

/* purgecss end ignore */
/* purgecss start ignore */

.formatted-text-container p,
.formatted-text-container ol,
.formatted-text-container ul,
.formatted-text-container pre,
.formatted-text-container blockquote,
.formatted-text-container h1,
.formatted-text-container h2,
.formatted-text-container h3,
.formatted-text-container h4,
.formatted-text-container h5,
.formatted-text-container h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  word-wrap: break-word;
}

.formatted-text-container h1, h2, h3, h4, h5, h6 {
  display: block;
  font-weight: bold;
  margin: 0.6em 0em;
}

.formatted-text-container .ql-size-huge {
  font-size: 2.5em;
}

.formatted-text-container h1 {
  font-size: 2em;
}

.formatted-text-container h2, .formatted-text-container .ql-size-large {
  font-size: 1.5em;
}

.formatted-text-container h3 {
  font-size: 1.17em;
}

.formatted-text-container h4 {
  font-size: 1em;
}

.formatted-text-container h5 {
  font-size: .83em;
}

.formatted-text-container h6 {
  font-size: .67em;
}

.formatted-text-container .ql-size-small {
  font-size: 0.65em;
}

.formatted-text-container ol,
.formatted-text-container ul {
  padding-left: 1.5em;
}

.formatted-text-container blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.formatted-text-container ol > li,
.formatted-text-container ul > li {
  list-style-type: none;
}

.formatted-text-container ul > li::before {
  content: '\2022';
}

.formatted-text-container ul[data-checked=true],
.formatted-text-container ul[data-checked=false] {
  pointer-events: none;
}

.formatted-text-container ul[data-checked=true] > li *,
.formatted-text-container ul[data-checked=false] > li * {
  pointer-events: all;
}

.formatted-text-container ul[data-checked=true] > li::before,
.formatted-text-container ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.formatted-text-container ul[data-checked=true] > li::before {
  content: '\2611';
}

.formatted-text-container ul[data-checked=false] > li::before {
  content: '\2610';
}

.formatted-text-container li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}

.formatted-text-container li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}

.formatted-text-container li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1em;
}

.formatted-text-container ol li:not(.ql-direction-rtl),
.formatted-text-container ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.formatted-text-container ol li.ql-direction-rtl,
.formatted-text-container ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.formatted-text-container ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.formatted-text-container ol li:before {
  content: counter(list-0, decimal) '. ';
}

.formatted-text-container ol li.ql-indent-1 {
  counter-increment: list-1;
}

.formatted-text-container ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}

.formatted-text-container ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.formatted-text-container ol li.ql-indent-2 {
  counter-increment: list-2;
}

.formatted-text-container ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}

.formatted-text-container ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.formatted-text-container ol li.ql-indent-3 {
  counter-increment: list-3;
}

.formatted-text-container ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}

.formatted-text-container ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.formatted-text-container ol li.ql-indent-4 {
  counter-increment: list-4;
}

.formatted-text-container ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}

.formatted-text-container ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.formatted-text-container ol li.ql-indent-5 {
  counter-increment: list-5;
}

.formatted-text-container ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}

.formatted-text-container ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.formatted-text-container ol li.ql-indent-6 {
  counter-increment: list-6;
}

.formatted-text-container ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}

.formatted-text-container ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.formatted-text-container ol li.ql-indent-7 {
  counter-increment: list-7;
}

.formatted-text-container ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}

.formatted-text-container ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.formatted-text-container ol li.ql-indent-8 {
  counter-increment: list-8;
}

.formatted-text-container ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}

.formatted-text-container ol li.ql-indent-8 {
  counter-reset: list-9;
}

.formatted-text-container ol li.ql-indent-9 {
  counter-increment: list-9;
}

.formatted-text-container ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}

.formatted-text-container .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.formatted-text-container li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.formatted-text-container .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.formatted-text-container li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.formatted-text-container .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.formatted-text-container li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.formatted-text-container .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.formatted-text-container li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.formatted-text-container .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.formatted-text-container li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.formatted-text-container .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.formatted-text-container li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.formatted-text-container .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.formatted-text-container li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.formatted-text-container .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.formatted-text-container li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.formatted-text-container .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.formatted-text-container li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.formatted-text-container .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.formatted-text-container li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.formatted-text-container .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.formatted-text-container li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.formatted-text-container .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.formatted-text-container li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.formatted-text-container .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.formatted-text-container li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.formatted-text-container .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.formatted-text-container li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.formatted-text-container .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.formatted-text-container li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.formatted-text-container .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.formatted-text-container li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.formatted-text-container .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.formatted-text-container li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.formatted-text-container .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.formatted-text-container li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.formatted-text-container .ql-video {
  display: block;
  max-width: 100%;
}

.formatted-text-container .ql-video.ql-align-center {
  margin: 0 auto;
}

.formatted-text-container .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.formatted-text-container .ql-bg-black {
  background-color: #000;
}

.formatted-text-container .ql-bg-red {
  background-color: #e60000;
}

.formatted-text-container .ql-bg-orange {
  background-color: #f90;
}

.formatted-text-container .ql-bg-yellow {
  background-color: #ff0;
}

.formatted-text-container .ql-bg-green {
  background-color: #008a00;
}

.formatted-text-container .ql-bg-blue {
  background-color: #06c;
}

.formatted-text-container .ql-bg-purple {
  background-color: #93f;
}

.formatted-text-container .ql-color-white {
  color: #fff;
}

.formatted-text-container .ql-color-red {
  color: #e60000;
}

.formatted-text-container .ql-color-orange {
  color: #f90;
}

.formatted-text-container .ql-color-yellow {
  color: #ff0;
}

.formatted-text-container .ql-color-green {
  color: #008a00;
}

.formatted-text-container .ql-color-blue {
  color: #06c;
}

.formatted-text-container .ql-color-purple {
  color: #93f;
}

.formatted-text-container .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.formatted-text-container .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.formatted-text-container .ql-size-small {
  font-size: 0.75em;
}

.formatted-text-container .ql-size-large {
  font-size: 1.5em;
}

.formatted-text-container .ql-size-huge {
  font-size: 2.5em;
}

.formatted-text-container .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.formatted-text-container .ql-align-center {
  text-align: center;
}

.formatted-text-container .ql-align-justify {
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.formatted-text-container .ql-align-right {
  text-align: right;
}

.formatted-text-container.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.formatted-text-container .ql-image-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
}

.formatted-text-container .ql-image-container img {
  -webkit-flex-basis: 15%;
          flex-basis: 15%;
  min-width: 15% !important;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding: 2px;
  border-radius: 4px;
}

/* purgecss end ignore */
