.service-element {
    width: 100%;
    min-width: 100%;
    transition: margin-left 200ms linear;
}

.service-element:first-of-type.index-2 {
    margin-left: -200%;
} .service-element:first-of-type.index-3 {
    margin-left: -300%;
} .service-element:first-of-type.index-4 {
    margin-left: -400%;
} .service-element:first-of-type.index-5 {
    margin-left: -500%;
} .service-element:first-of-type.index-6 {
    margin-left: -600%;
} .service-element:first-of-type.index-7 {
    margin-left: -700%;
} .service-element:first-of-type.index-8 {
    margin-left: -800%;
} .service-element:first-of-type.index-9 {
    margin-left: -900%;
} .service-element:first-of-type.index-10 {
    margin-left: -100%;
} .service-element:first-of-type.index-11 {
    margin-left: -1200%;
} .service-element:first-of-type.index-12 {
    margin-left: -1300%;
}

@media (min-width: 640px) {
    .service-element {
        width: 33%;
        min-width: 33%;
    }

    .service-element:first-of-type.index-2 {
        margin-left: -33.3%;
    } .service-element:first-of-type.index-3 {
        margin-left: -66.67%;
    } .service-element:first-of-type.index-4 {
        margin-left: -100%;
    } .service-element:first-of-type.index-5 {
        margin-left: -133.3%;
    } .service-element:first-of-type.index-6 {
        margin-left: -166.67%;
    } .service-element:first-of-type.index-7 {
        margin-left: -200%;
    } .service-element:first-of-type.index-8 {
        margin-left: -233.3%;
    } .service-element:first-of-type.index-9 {
        margin-left: -266.67%;
    } .service-element:first-of-type.index-10 {
        margin-left: -300%;
    } .service-element:first-of-type.index-11 {
        margin-left: -333.3%;
    } .service-element:first-of-type.index-12 {
        margin-left: -367.67%;
    }
}

@media (min-width: 1280px) {
    .service-element {
        width: 20%;
        min-width: 20%;
    }

    .service-element:first-of-type.index-2 {
        margin-left: -0%;
    } .service-element:first-of-type.index-3 {
        margin-left: -20%;
    } .service-element:first-of-type.index-4 {
        margin-left: -40%;
    } .service-element:first-of-type.index-5 {
        margin-left: -60%;
    } .service-element:first-of-type.index-6 {
        margin-left: -80%;
    } .service-element:first-of-type.index-7 {
        margin-left: -100%;
    } .service-element:first-of-type.index-8 {
        margin-left: -120%;
    } .service-element:first-of-type.index-9 {
        margin-left: -140%;
    } .service-element:first-of-type.index-10 {
        margin-left: -160%;
    } .service-element:first-of-type.index-11 {
        margin-left: -180%;
    } .service-element:first-of-type.index-12 {
        margin-left: -200%;
    }
}