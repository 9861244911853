
.opition-carousel-item {
    min-width: 78%;
}

@media (min-width: 640px) {
    .opition-carousel-item {
        min-width: 75%;
    }
}

@media (min-width: 768px) {
    .opition-carousel-item {
        min-width: 65%;
    }
}

@media (min-width: 1280px) {
    .opition-carousel-item {
        min-width: 75%;
    }
}

.home-service-item{
    transition: box-shadow linear 0.2s;
}

.home-service-item:hover {
    background: #FFFFFF;
    box-shadow: 5px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}