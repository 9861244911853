.zodiacIcon:hover svg path{
    stroke: white;
}

.zodiac-element, .base-zodiac {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    height: 170px;
    transition: margin-left 200ms linear;
    overflow: hidden;
}

.zodiac-element:first-of-type {
    margin-left: -500%;
}.zodiac-element:first-of-type.margin-3 {
    margin-left: -600%;
}.zodiac-element:first-of-type.margin-4 {
    margin-left: -700%;
}.zodiac-element:first-of-type.margin-5 {
    margin-left: -800%;
}.zodiac-element:first-of-type.margin-6 {
    margin-left: -900%;
}.zodiac-element:first-of-type.margin-7 {
    margin-left: -1000%;
}.zodiac-element:first-of-type.margin-8 {
    margin-left: -1100%;
}.zodiac-element:first-of-type.margin-9 {
    margin-left: -1200%;
}.zodiac-element:first-of-type.margin-10 {
    margin-left: -1300%;
}.zodiac-element:first-of-type.margin-11 {
    margin-left: -1400%;
}.zodiac-element:first-of-type.margin-12 {
    margin-left: -1500%;
}.zodiac-element:first-of-type.margin-13 {
    margin-left: -1600%;
}.zodiac-element:first-of-type.margin-14 {
    margin-left: -1700%;
}.zodiac-element:first-of-type.margin-15 {
    margin-left: -1800%;
}

/*
.zodiac-element.animating {
    min-width: 0px;
    width: 0px !important;
    overflow: hidden;
}*/

@media (min-width: 640px) {
    .zodiac-element {
        width: 33%;
        min-width: 33%;
    }

    .zodiac-element:first-of-type {
        margin-left: -133.3%;
    }.zodiac-element:first-of-type.margin-3 {
        margin-left: -166.7%;
    }.zodiac-element:first-of-type.margin-4 {
        margin-left: -200%;
    }.zodiac-element:first-of-type.margin-5 {
        margin-left: -233.3%;
    }.zodiac-element:first-of-type.margin-6 {
        margin-left: -266.7%;
    }.zodiac-element:first-of-type.margin-7 {
        margin-left: -300%;
    }.zodiac-element:first-of-type.margin-8 {
        margin-left: -333.3%;
    }.zodiac-element:first-of-type.margin-9 {
        margin-left: -366.7%;
    }.zodiac-element:first-of-type.margin-10 {
        margin-left: -400%;
    }.zodiac-element:first-of-type.margin-10 {
        margin-left: -433.3%;
    }.zodiac-element:first-of-type.margin-11 {
        margin-left: -466.7%;
    }.zodiac-element:first-of-type.margin-12 {
        margin-left: -500%;
    }.zodiac-element:first-of-type.margin-13 {
        margin-left: -533.3%;
    }.zodiac-element:first-of-type.margin-14 {
        margin-left: -566.7%;
    }.zodiac-element:first-of-type.margin-15 {
        margin-left: -600%;
    }
}

@media (min-width: 768px) {
    .zodiac-element {
        width: 20%;
        min-width: 20%;
    }


    .zodiac-element:first-of-type {
        margin-left: -60%;
    }.zodiac-element:first-of-type.margin-3 {
        margin-left: -80%;
    }.zodiac-element:first-of-type.margin-4 {
        margin-left: -100%;
    }.zodiac-element:first-of-type.margin-5 {
        margin-left: -120%;
    }.zodiac-element:first-of-type.margin-6 {
        margin-left: -140%;
    }.zodiac-element:first-of-type.margin-7 {
        margin-left: -160%;
    }.zodiac-element:first-of-type.margin-8 {
        margin-left: -180%;
    }.zodiac-element:first-of-type.margin-9 {
        margin-left: -200%;
    }.zodiac-element:first-of-type.margin-10 {
        margin-left: -220%;
    }.zodiac-element:first-of-type.margin-11 {
        margin-left: -240%;
    }.zodiac-element:first-of-type.margin-12 {
        margin-left: -260%;
    }.zodiac-element:first-of-type.margin-13 {
        margin-left: -280%;
    }.zodiac-element:first-of-type.margin-14 {
        margin-left: -300%;
    }.zodiac-element:first-of-type.margin-15 {
        margin-left: -320%;
    }
}

@media (min-width: 1280px) {
    .zodiac-element {
        width: 14.3%;
        min-width: 14.3%;
    }

    .zodiac-element:first-of-type {
        margin-left: -28.5%;
    }

    .zodiac-element:first-of-type.margin-3 {
        margin-left: -42.8%;
    }.zodiac-element:first-of-type.margin-4 {
        margin-left: -57.14%;
    }.zodiac-element:first-of-type.margin-5 {
        margin-left: -71.43%;
    }.zodiac-element:first-of-type.margin-6 {
        margin-left: -85.7%;
    }.zodiac-element:first-of-type.margin-7 {
        margin-left: -100%;
    }.zodiac-element:first-of-type.margin-8 {
        margin-left: -114%;
    }.zodiac-element:first-of-type.margin-9 {
        margin-left: -128.5%;
    }.zodiac-element:first-of-type.margin-10 {
        margin-left: -157.14%;
    }.zodiac-element:first-of-type.margin-11 {
        margin-left: -171.43%;
    }.zodiac-element:first-of-type.margin-12 {
        margin-left: -185.7%;
    }.zodiac-element:first-of-type.margin-13 {
        margin-left: -200%;
    }.zodiac-element:first-of-type.margin-14 {
        margin-left: -214%;
    }.zodiac-element:first-of-type.margin-15 {
        margin-left: -228.5%;
    }
}